import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SettingsService, TitleService } from '@delon/theme';
import { NzModalService } from 'ng-zorro-antd/modal';
import { filter } from 'rxjs/operators';
import {
  ShowyePageApiService,
  ShowyePageAuthService,
  ShowyePageIconService,
  ShowyePageMenuService,
  ShowyePageMessageService,
} from '@showye/showye-page-base';
import { EventManager } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';
import { ReuseTabService } from '@delon/abc/reuse-tab';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { PlatformLocation } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  downloadUrl = this.api.getDownloadUrl();
  userInfo: any = {};

  constructor(
    private router: Router,
    private titleSrv: TitleService,
    private modalSrv: NzModalService,
    private messageService: ShowyePageMessageService,
    private menuService: ShowyePageMenuService,
    private eventManager: EventManager,
    private settings: SettingsService,
    private auth: ShowyePageAuthService,
    private api: ShowyePageApiService,
    private icon: ShowyePageIconService,
    fb: FormBuilder,
    private settingsService: SettingsService,
    @Optional()
    @Inject(ReuseTabService)
    private reuseTabService: ReuseTabService,
    public notify: NzNotificationService,
    private location: PlatformLocation,
    private route: ActivatedRoute,
  ) {
    // this.location.onPopState((x: any) => {
    //   if (!x.state) {
    //     this.notify.error('不支持该操作！', '');
    //     this.router.navigate(['/exception/404'], { relativeTo: this.route });
    //   }
    // });
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((evt) => evt instanceof NavigationEnd)).subscribe((e: any) => {
      // 监听全局路由变更事件
      this.auth.refresh();
      this.userInfo = this.auth.user;
      this.menuService.resetMenuByPath(e.urlAfterRedirects ? e.urlAfterRedirects : e.url);
      this.titleSrv.setTitle();
      this.modalSrv.closeAll();
    });
    this.messageService.trigger().subscribe((type) => {
      this.router.navigate(['/my/message', !!type ? { type } : {}]);
    });
  }

  ngOnDestroy(): void {}
}

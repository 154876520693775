import { Injectable } from '@angular/core';
import { _HttpClient } from '@delon/theme';
import { Observable } from 'rxjs';
import { API } from './api';
import { ShowyePageApiPath, ShowyePageApiService, ShowyePageUtilService } from '@showye/showye-page-base';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
/**
 * 接口服务，专用从服务器请求数据。缓存管理也在这里进行。
 *
 *
 */
export class NbApiService extends ShowyePageApiService {
  constructor(protected baseApi: ShowyePageApiPath, private api: API, protected http: _HttpClient, protected coder: ShowyePageUtilService) {
    super(baseApi, http, coder);
  }

  public domainTree(): Observable<any> {
    return this.http.get(this.api.DOMAIN_TREE);
  }

  public getSameLevelDomainList(id: any): Observable<any> {
    return this.http.get(this.api.GET_SAME_LEVEL_DOMAIN_LIST(id));
  }

  public roleList(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_LIST, param);
  }

  public roleAdd(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_ADD, param);
  }

  public roleInfo(id: any): Observable<any> {
    return this.http.get(this.api.ROLE_INFO(id));
  }

  public roleEdit(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_EDIT, param);
  }

  public roleDelete(id: any): Observable<any> {
    return this.http.delete(this.api.ROLE_DELETE(id));
  }

  public roleConfig(param: any): Observable<any> {
    return this.http.post(this.api.ROLE_CONFIG, param);
  }

  public allRoles(): Observable<any> {
    return this.http.get(this.api.ALL_ROLES);
  }

  public getRoleListByDomainId(domainId: any, param: any): Observable<any> {
    return this.http.post(this.api.GET_ROLE_LIST_BY_DOMAIN(domainId), param);
  }

  public menuTree(): Observable<any> {
    return this.http.get(this.api.MENU_TREE);
  }

  public myperms(): Observable<any> {
    return this.http.get(this.api.MY_PERMS);
  }

  public menuDelete(param: any): Observable<any> {
    return this.http.post(this.api.MENU_DELETE, param);
  }

  public buttonTree(): Observable<any> {
    return this.http.get(this.api.BUTTON_TREE);
  }

  public buttonAdd(param: any): Observable<any> {
    return this.http.post(this.api.BUTTON_ADD, param);
  }

  public buttonEdit(param: any): Observable<any> {
    return this.http.post(this.api.BUTTON_EDIT, param);
  }

  public buttonDelete(id: any): Observable<any> {
    return this.http.delete(this.api.BUTTON_DELETE(id));
  }

  // 枚举字典相关
  public enumList(par: any): Observable<any> {
    return this.http.post(this.api.ENUM_LIST, par);
  }

  public getUserListByDomain(domainId: any, par: any): Observable<any> {
    return this.http.post(this.api.GET_USER_LIST_BY_DOMAIN(domainId), par);
  }

  // 用户列表
  public userList(par: any): Observable<any> {
    return this.http.post(this.api.USER_LIST, par);
  }

  // 新增用户
  public addUser(par: any): Observable<any> {
    return this.http.post(this.api.ADD_USER, par);
  }

  // 新增ott用户
  public addOTTUser(par: any): Observable<any> {
    return this.http.post(this.api.ADD_OTT_USER, par);
  }

  // 编辑用户
  public editUser(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_USER, par);
  }

  // 删除用户
  public deleteUser(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_USER(id));
  }

  public initPwd(id: any): Observable<any> {
    return this.http.get(this.api.INIT_PWD(id));
  }

  // 查看用户详情
  public userInfo(id: any): Observable<any> {
    return this.http.get(this.api.USER_INFO(id));
  }

  // 修改密码
  public changeMyPassword(param: any): Observable<any> {
    return this.http.post(this.api.MY_CHANGE_PASSWORD, param);
  }

  public data(): Observable<any> {
    return this.http.get(this.api.DATA_BASE_DATA);
  }

  public listLog(param: any): Observable<any> {
    return this.http.post(this.api.LIST_LOG, param);
  }

  public deviceTypeList(): Observable<any> {
    return this.http.get(this.api.DEVICE_TYPE_LIST);
  }

  public addDeviceTemplate(par: any): Observable<any> {
    return this.http.post(this.api.ADD_DEVICE_TEMPLATE, par);
  }

  public editDeviceTemplate(param: any): Observable<any> {
    return this.http.put(this.api.EDIT_DEVICE_TEMPLATE, param);
  }

  public deleteDeviceTemplate(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_DEVICE_TEMPLATE(id));
  }

  public deviceTemplateInfo(id: any): Observable<any> {
    return this.http.post(this.api.DEVICE_TEMPLATE_INFO(id));
  }

  public deleteAttributeDeviceTemplate(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_ATTRIBUTE_DEVICE_TEMPLATE(id));
  }

  public deleteActionDeviceTemplate(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_ACTION_DEVICE_TEMPLATE(id));
  }

  public deviceTemplateListOfSelect(): Observable<any> {
    return this.http.get(this.api.DEVICE_TEMPLATE_LIST_OF_SELECT);
  }

  public addAttrDeviceTemplate(par: any): Observable<any> {
    return this.http.post(this.api.ADD_ATTRIBUTE_DEVICE_TEMPLATE, par);
  }

  public editAttrDeviceTemplate(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_ATTRIBUTE_DEVICE_TEMPLATE, par);
  }

  public displayAttribute(id: any): Observable<any> {
    return this.http.put(this.api.DISPLAY_ATTRIBUTE(id));
  }

  public addActionDeviceTemplate(par: any): Observable<any> {
    return this.http.post(this.api.ADD_ACTION_DEVICE_TEMPLATE, par);
  }

  public editActionDeviceTemplate(par: any): Observable<any> {
    return this.http.post(this.api.EDIT_ACTION_DEVICE_TEMPLATE, par);
  }

  public addDevice(param: any): Observable<any> {
    return this.http.post(this.api.ADD_DEVICE, param);
  }

  public addDeviceAttribute(param: any): Observable<any> {
    return this.http.post(this.api.ADD_ATTRIBUTE_DEVICE, param);
  }

  public attributeListDevice(param: any): Observable<any> {
    return this.http.post(this.api.ATTRIBUTE_LIST_DEVICE, param);
  }

  public actionListDevice(id: any): Observable<any> {
    return this.http.get(this.api.ACTION_LIST_DEVICE(id));
  }

  public getRelationScene(id: any): Observable<any> {
    return this.http.get(this.api.GET_RELATION_SCENE(id));
  }

  public addDeviceBindScene(param: any): Observable<any> {
    return this.http.post(this.api.ADD_DEVICE_BIND_SCENE, param);
  }

  public deviceBindScene(param: any): Observable<any> {
    return this.http.post(this.api.BIND_DEVICE_SCENE, param);
  }

  public addScene(param: any): Observable<any> {
    return this.http.post(this.api.ADD_SCENE, param);
  }

  public deleteScene(id: any): Observable<any> {
    return this.http.post(this.api.DEL_SCENE(id));
  }

  public sceneList(param: any): Observable<any> {
    return this.http.post(this.api.SCENE_LIST, param);
  }

  public getUnbindDeviceList(param: any): Observable<any> {
    return this.http.post(this.api.GET_UNBIND_DEVICE_LIST, param);
  }

  public deviceListSceneId(id: any): Observable<any> {
    return this.http.get(this.api.DEVICE_LIST_SCENE_ID(id));
  }

  public saveLonlat(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_LONLAT, param);
  }

  public addLine(param: any): Observable<any> {
    return this.http.post(this.api.ADD_LINE, param);
  }

  public delLine(param: any): Observable<any> {
    return this.http.post(this.api.DEL_LINE, param);
  }

  public getLineList(id: any): Observable<any> {
    return this.http.post(this.api.GET_LINE_LIST(id));
  }

  public getSceneDeviceInfo(id: any): Observable<any> {
    return this.http.get(this.api.GET_SCENE_DEVICE_INFO(id));
  }

  public delSceneDevice(param: any): Observable<any> {
    return this.http.post(this.api.DEL_SCENE_DEVICE, param);
  }

  public editOnline(id: any): Observable<any> {
    return this.http.get(this.api.EDIT_ONLINE(id));
  }

  public editStatus(id: any): Observable<any> {
    return this.http.get(this.api.EDIT_STATUS(id));
  }

  public getInAttributeList(id: any): Observable<any> {
    return this.http.get(this.api.GET_IN_ATTRIBUTE_LIST(id));
  }

  public getOutAttributeList(id: any): Observable<any> {
    return this.http.get(this.api.GET_OUT_ATTRIBUTE_LIST(id));
  }

  public getActionList(id: any): Observable<any> {
    return this.http.get(this.api.GET_ACTION_LIST(id));
  }

  public preDeviceAttributeList(param: any): Observable<any> {
    return this.http.post(this.api.PRE_DEVICE_ATTRIBUTE_LIST, param);
  }

  public addInAttribute(param: any): Observable<any> {
    return this.http.post(this.api.ADD_IN_ATTRIBUTE, param);
  }

  public editInAttribute(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_IN_ATTRIBUTE, param);
  }

  public delInAttribute(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_IN_ATTRIBUTE(id));
  }

  public addOutAttribute(param: any): Observable<any> {
    return this.http.post(this.api.ADD_OUT_ATTRIBUTE, param);
  }

  public editOutAttribute(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_OUT_ATTRIBUTE, param);
  }

  public delOutAttribute(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_OUT_ATTRIBUTE(id));
  }

  public outAttributeById(id: any): Observable<any> {
    return this.http.get(this.api.OUT_ATTRIBUTE_BY_ID(id));
  }

  public bindCorrespond(param: any): Observable<any> {
    return this.http.post(this.api.BIND_CORRESPOND, param);
  }

  public editCorrespond(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_CORRESPOND, param);
  }

  public unbindCorrespond(id: any): Observable<any> {
    return this.http.delete(this.api.UNBIND_CORRESPOND(id));
  }

  public correspondListById(id: any): Observable<any> {
    return this.http.get(this.api.CORRESPOND_LIST_BY_ID(id));
  }

  public correspondConfig(id: any): Observable<any> {
    return this.http.get(this.api.CORRESPOND_CONFIG(id));
  }

  public sceneStart(param: any): Observable<any> {
    return this.http.post(this.api.SCENE_START, param);
  }

  public sceneRun(param: any): Observable<any> {
    return this.http.post(this.api.SCENE_RUN, param);
  }

  public sceneStop(id: any): Observable<any> {
    return this.http.get(this.api.SCENE_STOP(id));
  }

  public sceneStatus(id: any): Observable<any> {
    return this.http.get(this.api.SCENE_STATUS(id));
  }

  public devicePlay(id: any): Observable<any> {
    return this.http.get(this.api.DEVICE_PLAY(id));
  }

  public responseList(id: any): Observable<any> {
    return this.http.get(this.api.RESPONSE_LIST(id));
  }

  public addResponse(param: any): Observable<any> {
    return this.http.post(this.api.ADD_RESPONSE, param);
  }

  public editResponse(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_RESPONSE, param);
  }

  public delResponse(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_RESPONSE(id));
  }

  public addModbusCorrespond(param: any): Observable<any> {
    return this.http.post(this.api.ADD_MODBUS_CORRESPOND, param);
  }

  public editModbusCorrespond(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_MODBUS_CORRESPOND, param);
  }

  public modbusStatus(id: any): Observable<any> {
    return this.http.get(this.api.MODBUS_STATUS(id));
  }

  public openModbusSlave(id: any): Observable<any> {
    return this.http.get(this.api.OPEN_MODBUS_SLAVE(id));
  }

  public closeModbusSlave(id: any): Observable<any> {
    return this.http.get(this.api.CLOSE_MODBUS_SLAVE(id));
  }

  public registerListByCorrespondId(id: any): Observable<any> {
    return this.http.get(this.api.REGISTER_LIST_BY_CORRESPOND_ID(id));
  }

  public saveRegister(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_REGISTER, param);
  }

  public editRegister(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_REGISTER, param);
  }

  public delRegister(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_REGISTER(id));
  }

  public writeModbusData(param: any): Observable<any> {
    return this.http.post(this.api.WRITE_MODBUS_DATA, param);
  }

  public redModbusData(id: any): Observable<any> {
    return this.http.get(this.api.RED_MODBUS_DATA(id));
  }

  public loadEvent(param: any): Observable<any> {
    return this.http.post(this.api.LOAD_EVENT, param);
  }

  public updateAttributeValue(param: any): Observable<any> {
    return this.http.post(this.api.UPDATE_ATTRIBUTE_VALUE, param);
  }

  public addEvent(param: any): Observable<any> {
    return this.http.post(this.api.ADD_EVENT, param);
  }

  public editEvent(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_EVENT, param);
  }

  public delEvent(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_EVENT(id));
  }

  public eventList(param: any): Observable<any> {
    return this.http.post(this.api.EVENT_LIST, param);
  }

  public eventAttributeList(id: any): Observable<any> {
    return this.http.get(this.api.EVENT_ATTRIBUTE_LIST(id));
  }

  public addAttribute(param: any): Observable<any> {
    return this.http.post(this.api.ADD_ATTRIBUTE, param);
  }

  public attributeConfig(param: any): Observable<any> {
    return this.http.post(this.api.CONFIG_ATTRIBUTE, param);
  }

  public delAttribute(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_ATTRIBUTE(id));
  }

  public attributeById(id: any): Observable<any> {
    return this.http.get(this.api.ATTRIBUTE_ID(id));
  }

  public eventOptions(): Observable<any> {
    return this.http.get(this.api.EVENT_OPTIONS);
  }

  public editDevice(param: any): Observable<any> {
    return this.http.put(this.api.EDIT_DEVICE, param);
  }

  public editAttributeDevice(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_ATTRIBUTE_DEVICE, param);
  }

  public deviceList(param: any): Observable<any> {
    return this.http.post(this.api.DEVICE_LIST, param);
  }

  public listOfSelectDelive(param: any): Observable<any> {
    return this.http.get(this.api.LIST_OF_SELECT_DELIVE);
  }

  public correspondListDevice(id: any): Observable<any> {
    return this.http.post(this.api.CORRESPOND_LIST_DEVICE(id));
  }

  public unbindCorrespondDevice(param: any): Observable<any> {
    return this.http.post(this.api.UNBIND_CORRESPOND_DEVICE, param);
  }

  public bindCorrespondDevice(param: any): Observable<any> {
    return this.http.post(this.api.BIND_CORRESPOND_DEVICE, param);
  }

  public editBindCorrespondDevice(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_BIND_CORRESPOND_DEVICE, param);
  }

  public devicePublicEdit(param: any): Observable<any> {
    return this.http.put(this.api.DEVICE_PUBLIC_EDIT, param);
  }

  public deleteAttributeDevice(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_ATTRIBUTE_DEVICE(id));
  }

  public deleteDevice(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_DEVICE(id));
  }

  public deleteDeviceSystem(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_DEVICE_SYSTEM_INFO(id));
  }

  public deviceInfo(id: any): Observable<any> {
    return this.http.get(this.api.DEVICE_INFO(id));
  }

  public listEventMessage(param: any): Observable<any> {
    return this.http.post(this.api.LIST_EVENT_MESSAGE, param);
  }

  public listSubSystem(param: any): Observable<any> {
    return this.http.post(this.api.LIST_SUB_SYSTEM, param);
  }

  public listOfSelectSubSystem(param: any): Observable<any> {
    return this.http.get(this.api.LIST_OF_SELECT_SUB_SYSTEM);
  }

  public deleteSubSystem(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_SUB_SYSTEM(id));
  }

  public addSubSystem(param: any): Observable<any> {
    return this.http.post(this.api.ADD_SUB_SYSTEM, param);
  }

  public editSubSystem(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_SUB_SYSTEM, param);
  }

  public listOptions(): Observable<any> {
    return this.http.get(this.api.LIST_OPTIONS);
  }

  public addCorrespond(param: any): Observable<any> {
    return this.http.post(this.api.ADD_CORRESPOND, param);
  }

  public editTcpOrUdp(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_TCP_UDP_CORRESPOND, param);
  }

  public deleteCorrespond(id: any): Observable<any> {
    return this.http.delete(this.api.DELETE_CORRESPOND(id));
  }

  public saveCorrespondName(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_CORRESPOND_NAME, param);
  }

  public correspondList(param: any): Observable<any> {
    return this.http.post(this.api.CORRESPOND_LIST, param);
  }

  public saveCorrespondMethod(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_CORRESPOND_METHOD, param);
  }

  public saveCorrespondUrl(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_CORRESPOND_URL, param);
  }

  public correspondInfo(id: any): Observable<any> {
    return this.http.post(this.api.CORRESPOND_INFO(id));
  }

  public addQueryParam(param: any): Observable<any> {
    return this.http.post(this.api.ADD_QUERY_PARAM, param);
  }

  public editQueryParam(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_QUERY_PARAM, param);
  }

  public delQueryParam(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_QUERY_PARAM(id));
  }

  public addHeaderParam(param: any): Observable<any> {
    return this.http.post(this.api.ADD_HEADER_PARAM, param);
  }

  public editHeaderParam(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_HEADER_PARAM, param);
  }

  public delHeaderParam(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_HEADER_PARAM(id));
  }

  public addBodyParam(param: any): Observable<any> {
    return this.http.post(this.api.ADD_BODY_PARAM, param);
  }

  public editBodyParam(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_BODY_PARAM, param);
  }

  public delBodyParam(id: any): Observable<any> {
    return this.http.delete(this.api.DEL_BODY_PARAM(id));
  }

  public saveCorrespondBodyType(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_CORRESPOND_BODY_TYPE, param);
  }

  public saveBodyRaw(param: any): Observable<any> {
    return this.http.post(this.api.SAVE_BODY_PARAM, param);
  }

  public sendCorrespond(id: any): Observable<any> {
    return this.http.get(this.api.SEND_CORRESPOND(id));
  }

  public editMqtt(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_MQTT, param);
  }

  public addMqttSubscribe(param: any): Observable<any> {
    return this.http.post(this.api.ADD_MQTT_SUBSCRIBE, param);
  }

  public addMqttPublish(param: any): Observable<any> {
    return this.http.post(this.api.ADD_MQTT_PUBLISH, param);
  }

  public editSubscribe(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_SUBSCRIBE, param);
  }

  public removeSubscribe(param: any): Observable<any> {
    return this.http.post(this.api.REMOVE_SUBSCRIBE, param);
  }

  public publishMqtt(param: any): Observable<any> {
    return this.http.post(this.api.PUBLISH_MQTT, param);
  }

  public isConnected(id: any): Observable<any> {
    return this.http.get(this.api.IS_CONNECTED(id));
  }

  public connectMqtt(id: any): Observable<any> {
    return this.http.get(this.api.CONNECT_MQTT(id));
  }

  public disconnectMqtt(id: any): Observable<any> {
    return this.http.get(this.api.DISCONNECT_MQTT(id));
  }

  public listByClientId(id: any): Observable<any> {
    return this.http.get(this.api.LIST_BY_CLIENT_ID(id));
  }

  public publishListByClientId(id: any): Observable<any> {
    return this.http.post(this.api.PUBLISH_LIST_CLIENT_ID(id));
  }

  public bindCorrespondSystem(param: any): Observable<any> {
    return this.http.post(this.api.BIND_CORRESPOND_SYSTEM, param);
  }

  public unbindCorrespondSystem(param: any): Observable<any> {
    return this.http.post(this.api.UNBIND_CORRESPOND_SYSTEM, param);
  }

  public editCorrespondSystem(param: any): Observable<any> {
    return this.http.post(this.api.EDIT_CORRESPOND_SYSTEM, param);
  }

  public correspondListSystem(id: any): Observable<any> {
    return this.http.post(this.api.CORRESPOND_LIST_SYSTEM(id));
  }

  public taskList(param: any): Observable<any> {
    return this.http.post(this.api.TASK_LIST, param);
  }

  public deleteTask(id: any): Observable<any> {
    return this.http.post(this.api.TASK_DEL(id));
  }

  public getDeviceListByTaskId(id: any): Observable<any> {
    return this.http.get(this.api.GET_DEVICE_LIST_BY_TASK_ID(id));
  }

  public getLineListByTaskId(id: any): Observable<any> {
    return this.http.get(this.api.GET_LINE_LIST_BY_TASK_ID(id));
  }

  public taskNodeInfo(param: any): Observable<any> {
    return this.http.post(this.api.TASK_NODE_INFO, param);
  }

  public taskNodeDataList(param: any): Observable<any> {
    return this.http.post(this.api.NODE_DATA_LIST, param);
  }

  public inAttributeList(param: any): Observable<any> {
    return this.http.post(this.api.IN_ATTRIBUTE_LIST, param);
  }

  public outAttributeList(param: any): Observable<any> {
    return this.http.post(this.api.OUT_ATTRIBUTE_LIST, param);
  }

  public taskActionList(param: any): Observable<any> {
    return this.http.post(this.api.TASK_ACTION_LIST, param);
  }

  public taskCorrespondList(param: any): Observable<any> {
    return this.http.post(this.api.TASK_CORRESPOND_LIST, param);
  }

  public taskInfo(id: any): Observable<any> {
    return this.http.post(this.api.TASK_INFO(id));
  }

  public recordList(id: any): Observable<any> {
    return this.http.get(this.api.RECORD_LIST(id));
  }

  public recordListByDeviceId(param: any): Observable<any> {
    return this.http.post(this.api.RECORD_LIST_BY_DEVICE_ID, param);
  }

  public recordListBySceneId(param: any): Observable<any> {
    return this.http.post(this.api.RECORD_LIST_BY_SCENE_ID, param);
  }

  public listBySceneId(param: any): Observable<any> {
    return this.http.post(this.api.LIST_BY_SCENE_ID, param);
  }

  public recordListBySubsystemId(param: any): Observable<any> {
    return this.http.post(this.api.RECORD_LIST_BY_SUBSYSTEM_ID, param);
  }

  public recordListByParam(param: any): Observable<any> {
    return this.http.post(this.api.RECORD_LIST_BY_PARAM, param);
  }
}
